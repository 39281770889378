import logo from './images/logo.png'
import './App.scss'

function App () {
  return (
    <div className='app'>
      <div className='logo'>
        <div className='logo_circle'></div>
        <svg
          className='logo_name'
          xmlns='http://www.w3.org/2000/svg'
          width='371.531'
          height='225.622'
          viewBox='0 0 371.531 225.622'
        >
          <g
            id='Gruppe_1'
            data-name='Gruppe 1'
            transform='translate(-33.987 -48.858)'
          >
            <path
              id='Pfad_1'
              data-name='Pfad 1'
              d='M47.256,107.255c22.416-19.183,54.919-12.069,66.859,11.42,1-1.634,1.8-2.964,2.62-4.28a39.7,39.7,0,0,1,46.742-16.184C179.6,103.829,189.271,120,187.64,138.217c-.327,3.643-2.421,3.762-5.1,3.758q-26.2-.038-52.394-.011h-5.366c-1.432,9.631,6.87,21.508,17.164,24.213,12.071,3.172,22.879.571,31.165-9.116,2.763-3.23,4.634-2.97,7.274-.432,1.646,1.583,3.453,3,5.076,4.394-9.189,21.542-55.3,29.092-71.471-3.223-13.57,24.792-46.458,28.482-66.337,10.9v33.576C58.2,192.509,70.43,189.546,83.856,191.6c13.514,2.071,23.49,9.45,30.13,21.83.683-.952,1.2-1.568,1.6-2.249,9.519-16.007,23.774-22.049,41.855-19.607,16.781,2.266,29.283,16.758,30.022,34.76.512,12.456.456,10.339-9.716,10.4-15.967.094-31.936.025-47.9.025h-5.3c-.878,14.928,13.349,27.173,28.968,25.383,7.673-.879,14.616-3.718,19.441-9.989,2.685-3.489,4.792-3.4,7.61-.523,1.5,1.532,3.237,2.83,5.1,4.435-6.566,11.589-16.874,16.871-29.37,18.1-18.42,1.809-32.913-4.669-42.228-22.133-6.777,12.294-16.656,19.71-30.077,21.809-13.354,2.088-25.443-.8-36.428-10.785-.2,3.317-.354,5.964-.555,9.346a77.7,77.7,0,0,1-9.846-.146c-1.117-.144-2.295-1.827-2.927-3.071-.472-.93-.124-2.282-.124-3.447q0-104.808.054-209.617c0-2.021.512-5.632,1.31-5.787a30.045,30.045,0,0,1,10.249,0c.742.118,1.453,3.082,1.465,4.741.112,15.305.066,30.611.067,45.916Zm28.891,96.059c-16.789.07-29.918,13.047-29.872,29.529.045,16.365,13.413,29.442,30.09,29.435,16.759-.008,28.581-12.455,28.4-29.9C104.583,215.047,92.962,203.244,76.147,203.314Zm28.618-65.54c-.049-17.349-11.6-29.287-28.36-29.3-16.863-.016-30.077,12.814-30.13,29.255A29.706,29.706,0,0,0,76.6,167.421C93.138,167.294,104.814,155,104.765,137.774Zm69.309-8.309c-1.509-12.635-11.673-21.051-24.76-20.993-12.427.055-23.333,9.226-24.715,20.993Zm-.066,94.93c-1.134-13.023-12.333-21.718-26.528-21.032-11.427.552-22.644,10.93-22.582,21.032Z'
              fill='#fff'
            />
            <path
              id='Pfad_2'
              data-name='Pfad 2'
              d='M259.17,205.271c-4.165,0-7.654-.234-11.1.057-4.851.408-5.4-2.263-5.33-6.146.069-3.636.009-6.746,5.094-6.351,3.588.279,7.216.054,11.392.054V168.99q0-56.132,0-112.263c0-6.222,6.337-10,11.539-6.573,1.191.785,1.58,3.458,1.587,5.264.1,24.781.068,49.562.068,74.343v63.124c5.828,0,11.249.241,16.637-.071,4.982-.287,5.182,2.513,5.179,6.269s-.232,6.555-5.206,6.259c-5.284-.315-10.6-.071-16.287-.071-.124,1.826-.307,3.27-.309,4.715-.022,13.8-.052,27.608,0,41.412.035,8.984,4.26,12.262,13.111,10.33.325-.071.65-.138.973-.216,7-1.7,9.648.777,8.386,8.086a3.774,3.774,0,0,1-2.378,2.525c-5.364.884-10.845,2.333-16.166,1.941-10.664-.784-16.954-8.551-17.118-20.256-.2-14.134-.064-28.273-.073-42.41C259.169,209.593,259.17,207.789,259.17,205.271Z'
              fill='#fff'
            />
            <path
              id='Pfad_3'
              data-name='Pfad 3'
              d='M293.031,141.964c.627,18.219,17.614,29.729,34.986,23.976,4.976-1.648,9.714-5.057,13.571-8.706,3.031-2.867,4.818-3.459,7.687-.508,1.493,1.535,3.237,2.827,5.118,4.444-5.265,9.725-13.586,14.919-23.831,17.308-17.829,4.159-35.127-1.989-44.262-15.515-9.6-14.22-9.537-35.878.152-50.02a39.739,39.739,0,0,1,45.994-14.65c15.962,5.692,25.181,21.129,23.96,39.626-.259,3.912-2.435,4.068-5.33,4.062q-26.187-.054-52.374-.017Zm.279-12.616H342.7c.3-9.176-9.765-20.58-23.039-20.887C306.3,108.152,295.231,116.746,293.31,129.348Z'
              fill='#fff'
            />
            <path
              id='Pfad_4'
              data-name='Pfad 4'
              d='M249.115,112.741a53.628,53.628,0,0,0-4.749,2.994c-4.235,3.329-7.154,3.354-10.733-1.669-5-7.019-17.627-7.562-24.318-2-5.41,4.5-4.789,11.561,1.687,14.5,4.179,1.893,8.815,2.8,13.278,4.037,4.15,1.15,8.505,1.734,12.484,3.3a22.6,22.6,0,0,1,8.867,35.847c-13.64,16.3-47.41,11.737-56.437-7.86,1.14-.84,2.3-1.746,3.513-2.583,6.46-4.461,6.464-4.455,11.966,1.24,6.374,6.6,18.055,8.8,25.773,4.733,4.386-2.309,7.365-5.635,7.138-11.017-.213-5.053-3.755-7.285-7.8-8.619-4.869-1.606-9.947-2.567-14.9-3.941a75.756,75.756,0,0,1-9.926-3.188c-17.3-7.494-14.178-27.066-5.514-35.133C211.012,92.61,240.816,92.761,249.115,112.741Z'
              fill='#fff'
            />
            <path
              id='Pfad_5'
              data-name='Pfad 5'
              d='M206.01,192.885c.188,3.246.338,5.844.56,9.685,8.45-9.961,18.192-13.619,30.348-10.323-.335,3.315-.239,6.39-1.1,9.166a5.4,5.4,0,0,1-4.088,2.867c-16.351.351-24.417,8.229-24.6,24.669-.143,12.643-.253,25.295.057,37.933.124,5.065-2.176,5.817-6.355,5.612-3.75-.184-7.115.567-7.1-5.356.063-23.121-.278-46.245-.647-69.364-.06-3.792,1.346-5.132,4.937-4.917C200.482,193.005,202.963,192.885,206.01,192.885Z'
              fill='#fff'
            />
            <path
              id='Pfad_6'
              data-name='Pfad 6'
              d='M374.773,98.044c.3,3.236.526,5.678.856,9.246,7.585-8.384,16.046-12.4,26.55-10.68a4.026,4.026,0,0,1,3.324,4.331,40.05,40.05,0,0,1-.906,6.3c-.255,1.019-2.338,2.2-3.624,2.249-17.357.643-24.97,8.369-25.031,25.75-.043,12.31-.287,24.629.112,36.926.188,5.828-3.071,5.39-6.948,5.49-4.022.1-6.423-.282-6.421-5.49.009-22.949-.35-45.9-.764-68.847-.07-3.888,1.117-5.591,5.074-5.305C369.449,98.188,371.927,98.044,374.773,98.044Z'
              fill='#fff'
            />
            <path
              id='Pfad_7'
              data-name='Pfad 7'
              d='M315.072,232.612c0,11.617-.228,23.241.1,34.849.15,5.242-2.788,5.127-6.343,4.976-3.341-.142-7.063,1.059-7.011-4.786q.309-34.848-.009-69.7c-.053-5.582,3.24-5,6.819-5.109,3.9-.114,6.694.143,6.542,5.421C314.844,209.7,315.072,221.161,315.072,232.612Z'
              fill='#fff'
            />
          </g>
        </svg>
      </div>
      <h3>Büro für Produktgestaltung</h3>

      <div className='contact'>
            <hr/>

        <p>
          beslerberti GmbH
          <br />
          Tim Besler & Rafael Rico Berti
          <br />
          +49 6103 46988-12
          <br />
          Gärtnerweg 7
          <br />
          D-63263 Neu-Isenburg
          <br />
          <a href="mailto:mail@beslerberti.de">mail@beslerberti.de</a>
        </p>
      </div>
    </div>
  )
}

export default App
